import useFetch from '../../hooks/useFetch';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../images/logo.png'
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
function Header() {
	let llenar_header = 'v1/global/menu-principal'
	let url_nav = ''
	let title = 'Toyota Ecuador';
	const [menu] = useFetch(llenar_header);
	
	return (
		<>
			<header>
				<Navbar collapseOnSelect expand="lg">
					<Container fluid>
						<a 
							className="d-block d-sm-none" 
							title={title} 
							href="https://www.toyota.com.ec/">
							<img alt={title} src={logo} />
						</a>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="me-auto">
									{
										menu ? (
											<>
												{
													menu.map((c, i) => {
														url_nav = c.url_menu_principal.replace("admin/", "")
														return (
															<>
																{i === 2 || i === 4 || i === 5 ?
																	<Dropdown>
																		<Dropdown.Toggle variant="" id="dropdown-basic">
																			{c.nombre_menu_superior}
																		</Dropdown.Toggle>
																		{i === 2 &&
																			<Dropdown.Menu>
																				<Dropdown.Item href="/post-venta">Postventa</Dropdown.Item>
																				<Dropdown.Item href="/mantenimiento">Mantenimiento</Dropdown.Item>
																			</Dropdown.Menu>
																		}
																		{i === 4 &&
																			<Dropdown.Menu>
																				<Dropdown.Item href="https://toyotago.com.ec/" target="_blank">Toyota GO</Dropdown.Item>
																				<Dropdown.Item href="/toyota-gazoo-racing">Toyota Gazoo Racing</Dropdown.Item>
																				<Dropdown.Item href="/sostenibilidad">Sostenibilidad Toyota</Dropdown.Item>
																			</Dropdown.Menu>
																		}
																		{i === 5 &&
																			<Dropdown.Menu>
																				<Dropdown.Item href="/blog">Blog</Dropdown.Item>
																				<Dropdown.Item href="/dream-car">Dream Car</Dropdown.Item>
																			</Dropdown.Menu>
																		}
																	</Dropdown>
																	: <Nav.Link key={i} href={url_nav} className={""} >{c.nombre_menu_superior}</Nav.Link>
																}
																{i === 2 &&
																	<a 
																		className="d-none d-lg-block" 
																		title={title}
																		href="https://www.toyota.com.ec/">
																		<img alt={title} src={logo} />
																	</a>
																}
															</>
														)
													})
												}
											</>
										) : (
											null
										)
									}
								</Nav>
							</Navbar.Collapse>
					</Container>
				</Navbar>
			</header>
		</>
	);
}

export default Header;